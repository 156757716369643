section {
    padding: 60px 0;
    overflow: hidden;
}


.streamlineRecruitment-title {
    text-align: center;
    padding-bottom: 30px;
}
.streamlineRecruitment-title h2 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    color: #000;
}

.streamlineRecruitment-title p {
    color: #000;
}


/* streamlineRecruitment-box start */

.streamlineRecruitment-box {
    padding: 20px;
    border: 1px solid #575757;
    background: #171717;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.streamlineRecruitment-box:hover {
    background: #C08725;
}

.streamlineRecruitment-box h5 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 40px;
    padding-bottom: 20px;
    position: relative;
}
.streamlineRecruitment-box  h5::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
}
.streamlineRecruitment-box h5::after {
    content: "";
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    background: #fffffff2;
    bottom: -6px;
    left: calc(50% - 10px);
    transform: rotate(134deg);
}

.streamlineRecruitment-box img {
    margin-bottom: 15px;
}
/* service-box end */