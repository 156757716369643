section {
    padding: 60px 0;
    overflow: hidden;
}

.who-box-section {
    color: #fff;
    background: #fff;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 3em;
}

.who-box {
    background: #000;
    color: #fff;
    padding: 50px 25px 33px;
    margin-bottom: 10px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 99999;
    height: 100%;
}
.whoarewe-title h2{
    position: relative;
    z-index: 99999;
    color: #303030;
    font-size: 38px;
    font-weight: 700;
}

.box-m-8 {
    margin-top: 8em;
}