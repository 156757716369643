.btn-contact {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;   
    padding: 0.5rem 1.75rem;
    font-size: 1rem;
    line-height: 1.5;
    text-decoration: auto;
    border-radius: 50px;
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    margin-right: 2em;
}

.btn-contact:hover {
    color: #ffc107;
    background-color: #212529;
    border-color: #212529;
    border-bottom: none!important;
} 