section {
    padding: 60px 0;
    overflow: hidden;
}

.aboutCta-section {
    background: #171717;
}

.aboutCta-section h2{
    text-align: center;
    padding-bottom: 30px;
    color: #000000;
}

.aboutCta-section p {
    font-size: 20px;
    color: #444444;
}


.gs-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px 30px;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-text-decoration: auto;
    text-decoration: auto;
    border-radius: 50px;
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
    margin-right: 2em;
}
.gs-btn:hover {
    color: #ffc107;
    background-color: #212529;
    border-color: #212529;
    border-bottom: none!important;
}


.gs-btn2 {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px 30px;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-text-decoration: auto;
    text-decoration: auto;
    border-radius: 50px;
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
}
.gs-btn2:hover {
    color: #000;
    background-color: #00000000;
    border-color: #ffc107;
}

.modal-header {
    display: block;
    border-bottom: var(--bs-modal-header-border-width) solid #dee2e600;
    text-align: center;
    padding-bottom: 0;
}
button.btn-close {
    position: absolute;
    right: 23px;
    top: 25px;
}
.modal-content {
    padding: 2em;
}

.react-tel-input .form-control {
    width: 100% !important;
    height: 40px !important;
}

.modal-content .form-check-input[type=radio] {
    border-radius: 5%;
}

.mr-2 {
    margin-right: 10px;
}