.bg-hader {
    background: linear-gradient(90deg, #394D54 0%, #66757A 100%);
}
.navbar-nav {
    padding-right: 40px;
}
a.nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px!important;
    margin: 0 5px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
    border-bottom: 2px solid #ffa60000;
}

.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover>a {
    color: #fff;
    border-bottom: 2px solid #FFA500;
}

.navbar-nav .nav-link.active {
    color: rgb(255 255 255 / 90%);    
    border-bottom: 2px solid #FFA500;
}
a.logo.navbar-brand {
    border: none;
}

.navbar-toggler {
    background-color: #e7e7e7;
}