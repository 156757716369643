section {
    padding: 60px 0;
    overflow: hidden;
}

.about-text-section {
    background: #1F1F1F;
    margin-top: -1px;
}

.about-text-title {
    text-align: center;
    padding-bottom: 30px;
}
.about-text-title h2 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 30px;
    padding-bottom: 20px;
    position: relative;
    color: #fff;
}
.about-text-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 3px;
    background: #ffc107;
    bottom: 1px;
    left: calc(50% - 60px);
}

.about-text-title p {
    color: #fff;
    text-align: left;
}


/* service-box start */
