section {
    padding: 60px 0;
    overflow: hidden;
}

.aboutabout-testimonial-section h2{
    text-align: center;
    padding-bottom: 10px;
}

.view-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 7px 45px;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-text-decoration: auto;
    text-decoration: auto;
    border-radius: 50px;
    color: #000;
    background-color: #ffc10700;
    border: 1px solid #ffc107;
}
.view-btn:hover {
    color: #ffc107;
    background-color: #212529;
    border-color: #212529;
}

/* slider  */

.about-testimonial-box {
    border: 1px solid #FAFAFA;
    box-shadow: -6px 4px 13px rgba(219, 219, 219, 0.25), 6px 0px 13px #DBDBDB;
    border-radius: 8px;
    margin: 20px;
    padding: 20px
}

.slick-slide img {
    display: initial!important;
    border-radius: 50%;
    margin-bottom: 20px;
}

.slick-dots li button:before {
    font-size: 15px!important;
}


.slick-slide {
    opacity: 0.3;
}

.slick-current {
    opacity: 1.3;
}