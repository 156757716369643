
.hero {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    position: relative;
}
.hero::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 130%;
    height: 96%;
    background: #D8B476;
    opacity: 1.3;
    z-index: 0;
    border-radius: 0 0 50% 50%;
    transform: translateX(-50%) translateY(18px) rotate(357deg);
}
.hero::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 130%;
    height: 95%;
    background-image: url('/public/img/heroImage.jpeg');
    background-size: cover;
    /* background: linear-gradient(to right, #d9d9d9, #d9d9d9) center top no-repeat; */
    z-index: 0;
    border-radius: 0 0 50% 50%;
    transform: translateX(-50%) rotate(0deg);
}
.hero-text {
    position: inherit;
    z-index: 99999999;
}

.hero-text h4{
    color: #c08725;
    font-size: 47px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 30px;
}
.hero-text p {
    color: #222;
    font-size: 18px;
}

.rcb-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px 30px;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-text-decoration: auto;
    text-decoration: auto;
    border-radius: 50px;
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    margin-right: 2em;
}
.rcb-btn:hover {
    color: #ffc107;
    background-color: #212529;
    border-color: #212529;
    border-bottom: none!important;
}

@media only screen and (max-width: 786px) {
    .hero-text h4 {
        font-size: 43px;
    }
    .hero {
        height: 62vh;
    }
  }