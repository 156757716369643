footer{
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 7em;
    padding-bottom: 30px;
}
footer::before {
    content: "";
    position: absolute;
    left: 51%;
    top: 0px;
    width: 132%;
    height: 96%;
    background: #D8B476;
    opacity: 1.3;
    z-index: 0;
    border-radius: 0 0 50% 50%;
    transform: translateX(-52%) translateY(16px) rotate(176deg);
}
footer::after   {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -1px;
    width: 130%;
    height: 94%;
    background: linear-gradient(to right, rgb(0 0 0), rgb(0 0 0)),center top no-repeat;
    z-index: 0;
    border-radius: 0 0 50% 50%;
    transform: translateX(-47%) rotate(180deg);
}

.footer-middle {
    position: relative;
    z-index: 99999;
}

footer .border-top {
    position: relative;
    z-index: 99999;
}

/* Social Link css Start  */
.social-links a {
    font-size: 18px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

.social-links a:hover{
    color: #c6ad63;
}
/* Social Link css End  */


/* Footer Link css start  */
.footer-links ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    flex-wrap: wrap;
}
.footer-links li {
    position: relative;
}
.footer-links li .fa {
    padding-right: 5px;
    font-size: 10px;
    color: #7FAF47;
}

.footer-links li a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 10px 0 10px 15px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
}
/* Footer Link css End  */

/* Copyright css start  */
.copyright {
    padding-top: 30px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
/* Copyright css End  */

/* News Letter Section css start  */
.footer-newsletter {
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    z-index: 9999;
    color: #ebe9e9;
}

.footer-newsletter h4 {
    font-weight: 600;
    font-size: 32px;
}

.footer-newsletter p{
    font-weight: 400;
    font-size: 16px;
}

.mid-section {
    position: relative;
    z-index: 9999;
    color: #ebe9e9;
}

.copyright {
    position: relative;
    z-index: 9999;
    color: #ebe9e9;
}

.footer-newsletter form {
    margin-top: 20px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
}
.footer-newsletter form input[type=email] {
    border: 0;
    padding: 8px;
    width: calc(100% - 0px);
}
.footer-newsletter form input[type=email]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9F9F9F;
    font-weight: 400;
  }
  .footer-newsletter form input:focus-visible {
    outline-offset: 0px;
    outline: none;
  }
.footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    font-size: 16px;
    padding: 0 10px;
    margin: 3px;
    background: #D8B476;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
}

/* News Letter Section css End  */

/* media 678px */

@media only screen and (max-width: 678px) {
    footer {
        height: 87vh;
    }
    .copyright {
        padding-top: 22px;
    }
    footer::after {
        content: "";
    position: absolute;
    left: 50%;
    bottom: -12px;
    width: 160%;
    height: 95%;
    background: linear-gradient(to right, rgb(0 0 0), rgb(0 0 0)),center top no-repeat;
    z-index: 0;
    border-radius: 0 0 46% 45%;
    transform: translateX(-50%) rotate(180deg);
    }
    footer::before {
        content: "";
        position: absolute;
        left: 49%;
        top: 0;
        width: 144%;
        height: 78%;
        background: #D8B476;
        opacity: 1.3;
        z-index: 0;
        border-radius: 0% 0 43% 51%;
        transform: translateX(-44%) translateY(30px) rotate(177deg);
    }
  }
