section {
    padding: 60px 0;
    overflow: hidden;
}

.contact-section {
    background: #171717;
    height: 48em;
    margin-bottom: -140px;
    color: #fff;
}

.contat-from {
    margin-left: 5em;
}

@media only screen and (max-width: 786px) {
    .contact-section {
        background: #171717;
        height: 80em;
        margin-bottom: -140px;
        color: #fff;
    }

    .contat-from {
        margin-left: 0em;
    }
  }

label {
    margin-bottom: 7px;
}

.contact-text h2 {
font-weight: 600;
font-size: 40px;
line-height: 26px;
margin-bottom: 1.5em;
}

.email-box {
    margin: 40px 0px;
}

.phone-box {
    margin: 40px 0px;
}

.socal-box {
    margin: 40px 0px;
}

.socal-box a {
    font-size: 18px;
    display: inline-block;
    color: #AE894A;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: left;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

.btn-form-contact {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 1.75rem;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-text-decoration: auto;
    text-decoration: auto;
    border-radius: 50px;
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    margin-right: 2em;
}

.btn-form-contact:hover {
    color: #ffc107;
    background-color: #212529;
    border-color: #212529;
    border-bottom: none!important;
}