section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .services-section {
    background: #1F1F1F;
  }
  
  .service-title {
    text-align: center;
    padding-bottom: 30px;
  }
  .service-title h2 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 30px;
    padding-bottom: 20px;
    position: relative;
    color: #fff;
  }
  .service-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 3px;
    background: #ffc107;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .service-title p {
    color: #fff;
  }
  
  
  /* service-box start */
  
  .service-box {
    padding: 20px;
    border: 1px solid #575757;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .service-box:hover {
    background: #C08725;
  }
  
  .service-box h5 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 40px;
    padding-bottom: 20px;
    position: relative;
  }
  .service-box  h5::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  .service-box h5::after {
    content: "";
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    background: #fffffff2;
    bottom: -6px;
    left: calc(50% - 10px);
    transform: rotate(134deg);
  }
  
  .service-box img {
    margin-bottom: 15px;
  }
  .service-box-container {
    position: relative;
  }
  
  .overlay-card {
    position: absolute;
    padding: 20px;
    border: 1px solid #575757;
    background-color: #1f1f1f;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .overlay-card.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.2);
  }
  
  .service-box-container:hover .overlay-card {
    opacity: 1;
    background: #C08725;
    height: 300px;
    width: 66%;
  }
  .service-box-col.hide .service-box {
    opacity: 0;
    transition: opacity 0.5s ease;
    background: #C08725;
  }
  
  /* service-box end */