section {
    padding: 60px 0;
    overflow: hidden;
}

.fact-section {
    background: #171717;
    color: #fff;
    margin-bottom: -137px;
    height: 600px;
}

.fact-section h2{
    text-align: center;
    padding-bottom: 30px;
}

.fact-section h1 {
    font-size: 3.1rem;
}

.fact-section h6 {
    font-size: 25px;
    color: #656565;
    letter-spacing: 2px;
}

.fact-box {
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 14px 19px #171717;
    border-radius: 8px;
    color: #000;
    text-align: center;
    padding: 2em;
    margin-bottom: 20px;
}


.gs-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px 30px;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-text-decoration: auto;
    text-decoration: auto;
    border-radius: 50px;
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
    margin-right: 2em;
}
.gs-btn:hover {
    color: #ffc107;
    background-color: #212529;
    border-color: #212529;
    border-bottom: none!important;
}

@media only screen and (max-width: 786px) {
    .fact-section {
        background: #171717;
        color: #fff;
        margin-bottom: -150px;
        height: 1000px;
    }
  }