section {
    padding: 60px 0;
    overflow: hidden;
}

/*  */

.testimonial-title {
    text-align: center;
    padding-bottom: 30px;
}
.testimonial-title h2 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 30px;
    padding-bottom: 20px;
    position: relative;
    color: #1F1F1F;
}
.testimonial-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 3px;
    background: #ffc107;
    bottom: 1px;
    left: calc(50% - 60px);
}

.testimonial-title p {
    color: #1F1F1F;
}
/*  */
/* slider section start */

.testimonial-section .carousel-caption {
    position: initial!important;
    color: #000;
    height: 280px;
    padding: 25px;
}
.testimonial-section .carousel-item {
    border: 1px solid #FAFAFA;
    box-shadow: -6px 4px 13px rgba(219, 219, 219, 0.25), 6px 0px 13px #DBDBDB;
    border-radius: 8px;
}
.testimonial-section .carousel-inner {
    overflow: inherit;
}
.testimonial-section .carousel-indicators {
    right: 0;
    left: 0;
    margin-left: 0%;
    border: -6;
    margin-right: 0%;
    margin-bottom: -3rem;
}
.testimonial-section .carousel-caption p {
    font-size: 16px;
    margin-bottom: 45px;
    text-align: center;
    color: #606060;
}
.testimonial-section .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #746f6f;
}

.testimonial-section .carousel-caption img {
    border-radius: 50%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 786px) {
    .testimonial-section .carousel {
        margin-bottom: 80px;
    }
  }
/* slider section end */


/* text start */

.testimonial-iconbox {
    text-align: center;
    margin-bottom: 40px;
}

.testimonial-iconbox img {
    margin-bottom: 30px;
}

.testimonial-iconbox h4{
    margin-bottom: 20px;
    color: #4A4A4A;
    
}

.testimonial-iconbox p{
    color: #4A4A4A;
}

/* text end */