section {
    padding: 60px 0;
    overflow: hidden;
}

.about-section h3 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 15px;

}

.carousel-control, .carousel-control-prev, .carousel-control-next {
    width: 50px;
    cursor: pointer;
    display: none;
}

.about-section h5 {
    color: #ffc107;
}

.about-section p {
    font-size: 16px;
    margin-bottom: 45px;
}

.about-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px 30px;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-text-decoration: auto;
    text-decoration: auto;
    border-radius: 50px;
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    margin-right: 2em;
}
.about-btn:hover {
    color: #ffc107;
    background-color: #212529;
    border-color: #212529;
    border-bottom: none!important;
}

.carousel-caption {
    position: initial!important;
    color: #000;
    height: 180px;
}
.carousel-indicators {
    right: auto;
    left: 0;
    margin-left: 0%;
}
.carousel-caption p {
    font-size: 16px;
    margin-bottom: 45px;
    text-align: left;
}
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #746f6f;
}

/* img box  */

.img-box1 {
    position: relative;
    margin-right: 0px;
    padding-bottom: 45px;
    z-index: 2;
}

.img-box1::after {
    content: "";
    position: absolute;
    left: 26%;
    top: 54px;
    width: 64%;
    height: 95%;
    border: 5px solid #ffc107;
}

.img-box1 .img1 {
    display: inline-block;
    overflow: hidden;
    margin-left: 10em;
}

.img-box1 .img1 img{
    width: 100%;
}

.img-box1 .img2 {
    position: absolute;
    bottom: 10px;
    right: 33px;
}

.img-box1 .img2 img {
    width: 77%;
}


@media only screen and (max-width: 786px) {
    .img-box1 {
        margin-top: 60px;
    }
    .img-box1 .img2 img {
        width: 59%;
    }
    .about-section h3 {
        font-size: 26px;
    }
    .carousel-caption {
        height: 200px;
    }
  }